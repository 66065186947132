import React from 'react';
import ReactDOM from 'react-dom';

import PostsLayout from 'src/resources/PostsLayout';

document.addEventListener('DOMContentLoaded', () => {
  const layoutContainer = document.querySelector('[data-react="PostsLayout"]');
  if (layoutContainer) {
    const props = JSON.parse(layoutContainer.getAttribute('data-props'));
    ReactDOM.render(<PostsLayout {...props} />, layoutContainer);
  }
});
