import React from 'react';
import ReactDOM from 'react-dom';

import Form from 'src/forms/Form';
import FormResponse from 'src/forms/FormResponse';

document.addEventListener('DOMContentLoaded', () => {
  const formElements = document.querySelectorAll('[data-react="Form"]');
  [...formElements].forEach((formElement) => {
    const props = JSON.parse(formElement.getAttribute('data-props'));
    ReactDOM.render(<Form {...props} />, formElement);
  });

  const responseElements = document.querySelectorAll('[data-react="FormResponse"]');
  [...responseElements].forEach((responseElement) => {
    const props = JSON.parse(responseElement.getAttribute('data-props'));
    ReactDOM.render(<FormResponse {...props} />, responseElement);
  });
});
