import React from 'react';
import ReactDOM from 'react-dom';
import Balance from 'src/rewards/Balance';

document.addEventListener('DOMContentLoaded', () => {
  const balanceContainer = document.getElementById('rewards-balance');
  if (balanceContainer) {
    const url = balanceContainer.getAttribute('data-url');
    ReactDOM.render(<Balance url={url} />, balanceContainer);
  }
});
