import React from 'react';
import ReactDOM from 'react-dom';
import LeaderboardSelect from 'src/leaderboard/LeaderboardSelect';

document.addEventListener('DOMContentLoaded', () => {
  const leaderboardTab = document.getElementById('leaderboard');
  if (leaderboardTab) {
    const url = leaderboardTab.attributes['data-url'].value;
    ReactDOM.render(<LeaderboardSelect url={url} />, leaderboardTab);
  }
});
