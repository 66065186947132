import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ProfilePic = (props) => {
  const style = {
    backgroundImage: `url(${props.image})`,
  };
  return <div className={classNames('profile-pic', props.size, props.className)} style={style} />;
};

export default ProfilePic;

ProfilePic.propTypes = {
  image: PropTypes.string.isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
};

ProfilePic.defaultProps = {
  size: 'small',
  className: '',
};
