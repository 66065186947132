/* eslint-env jquery */
import { Options } from 'select2';

export const StandardConfig: Options = {
  theme: 'bootstrap-5',
  width: 'auto',
};

export const ClientSelectConfig: Options = {
  theme: 'bootstrap-5',
  placeholder: 'Client',
  minimumResultsForSearch: 2,
  minimumInputLength: 2,
  allowClear: true,
  dropdownAutoWidth: true,
  width: 'auto',
  ajax: {
    type: 'GET',
    url: '/admin/clients.json',
    dataType: 'json',
    data(params) {
      return {
        q: {
          name_cont: params.term,
        },
        page: params.page || 1,
      };
    },
    processResults(data) {
      return {
        results: $.map(data, item => ({ text: item.name, id: item.id })),
        pagination: { more: data.length === 25 },
      };
    },
    cache: true,
  },
};

export const ClientSelectMultiConfig: Options = {
  ...ClientSelectConfig,
  theme: 'default',
  width: '100%',
  multiple: true,
};

export const UserSelectConfig: Options<any> = {
  theme: 'bootstrap-5',
  placeholder: 'User',
  minimumResultsForSearch: 2,
  minimumInputLength: 2,
  ajax: {
    type: 'GET',
    url: '/admin/users.json',
    dataType: 'json',
    data(params) {
      return {
        q: {
          full_name_cont: params.term,
        },
      };
    },
    processResults(data) {
      return {
        results: $.map(data, item => ({
          text: item.full_name,
          id: item.id,
          client: item.client,
          employeeId: item.employee_id,
        })),
      };
    },
    cache: true,
  },
  templateResult(user) {
    if (user.loading) {
      return user.text;
    }

    return $(`
      <div class="row">
        <div class="col-lg-12">
          <strong>${user.text}</strong>
        </div>
        <div class="col-lg-12">
          ${user.client}
        </div>
        <div class="col-lg-12">
          <em>
            Employee ID: ${user.employeeId || 'None'}
          </em>
        </div>
      </div>
    `);
  },
};

// TODO: this will probably eventually not be needed
// see: https://github.com/select2/select2/issues/5993
export const setupInputFocus = () => {
  $(document).on('select2:open', () => {
    (document.querySelector('.select2-container--open .select2-search__field') as HTMLInputElement).focus();
  });
};
