import React from 'react';
import PropTypes from 'prop-types';

const SelectOption = props => (
  <div className="select-option" data-select-option>
    <span>{props[props.identifier]}</span>
  </div>
);

export default SelectOption;

SelectOption.propTypes = {
  identifier: PropTypes.string,
};

SelectOption.defaultProps = {
  identifier: 'name',
};
